<template>
  <v-row class="mx-5 mt-5"> 
    <v-col cols=12>
      <v-row>
        <v-col
          cols="12"
          md="4"
          offset-md="4"
        >
          <v-form ref="form" lazy-validation v-model="valid"> 
            <v-card flat>
              <v-card-text class="mb-0 pb-0">
                <p class="text-h6">Hello there.</p>
                <p class="mt-n3"> Please sign in to continue </p>
              </v-card-text>
              <v-card-text>
                  <v-text-field
                  label="Enter Email"
                  outlined
                  placeholder="name@gmail.com"
                  class=""
                  v-model="email"
                  :error-messages="error.email"
                  :rules= "[v => !!v || 'email is required']"
                  >
                  </v-text-field>
                  <v-text-field
                  label="Enter Password"
                  outlined
                  password="true"
                  type="password"
                  placeholder="password"
                  class=""
                  v-model="password"
                  :error-messages="error.password"
                  :rules= "[v => !!v || 'Password is required']"
                  >
                  </v-text-field>
                  <v-btn type="submit" :color="sendyMidnightBlue20" dark shaped block large @click.prevent="signIn" :loading="loading"> Sign in</v-btn>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text depressed :color="sendyAzureTransporter30" @click="$router.push('/register')">Sign up?</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import colorMxn from '@/mixins/color_mixins';
import { mapActions , mapMutations, mapGetters } from 'vuex';

export default {
  name: "login",
  mixins: [colorMxn],
  data() {
    return {
      email: '',
      password: '',
      error: {},
      loading: false,
      valid: true,
    }
  },
  computed: {
    ...mapGetters(['getErrors'])
  },
  watch: {
    getErrors(val) {
      this.error = val;
    }
  },
  methods: {
    ...mapMutations(['setAccessToken', 'setRefreshToken', 'setSession']),
    ...mapActions(['loginPostActions']),
    async signIn() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const payload = {
        email: this.email,
        password: this.password,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/auth/login'
      };

      const response = await this.loginPostActions(fullPayload);
      if (response.status === 200) {
        const accessToken = response.data.token;
        const refreshToken = response.data.refreshToken;

        this.setAccessToken(accessToken);
        this.setRefreshToken(refreshToken);

        localStorage.setItem('jwtToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        this.loading = false;
        this.$router.push('/new');
      }
      this.loading = false;
    },
  }
}
</script>